import { createClient } from "microcms-js-sdk";

const Client = async (setArticles) => {
  const client = createClient({
    serviceDomain: "donguri",
    apiKey: "773418f8-c8e9-4c8a-9f21-e5b22ee52937",
  });
  const data = await client.get({ endpoint: "newly" });
  setArticles(data.contents);
};

export default Client;
