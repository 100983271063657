import React from "react";
import Client from "./components/client";
import Article from "./components/article";
import Mabashi from "./components/mabashi";
import { useEffect } from "react";
import "./App.scss";

const App = () => {
  //Ga();
  const [articles, setArticles] = React.useState(null);

  useEffect(() => {
    Client(setArticles);
    Mabashi();
  }, []);

  return (
    <>
      <header className="header">
        <div className="header___titlewrap">
          <h1 className="header___titlewrap___title">DOJO.DONGURI.IN</h1>
          <h2 className="header___titlewrap___subtitle">
            背なについてるビスケの粉を払ってくれるなおふくろよ
          </h2>
        </div>
      </header>
      <section className="section">
        {articles
          ? articles.map((art) => (
              <Article
                date={art.created}
                title={art.title}
                body={art.body}
                key={art.id}
              />
            ))
          : "Loading..."}
      </section>
    </>
  );
};

export default App;
