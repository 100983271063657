import { UUID } from "uuidjs";
import dayjs from "dayjs";

const Mabashi = async () => {
  let uuid = localStorage.getItem("mabashi");
  const now = dayjs();
  if (!uuid) {
    uuid = UUID.generate();
    console.log(uuid);
    localStorage.setItem("mabashi", uuid);
  }
  const response = await fetch(
    "https://k9ya97cxve.execute-api.ap-northeast-1.amazonaws.com/items",
    {
      method: "PUT",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        yymmdd: now.format("YYMMDD"),
        hh_event_uuid: now.format("HH") + "#view#" + uuid,
        hh: now.format("HH"),
        event: "view",
        uuid: uuid,
        ua: navigator.userAgent,
      }),
    }
  ).catch((res) => {
    console.log(res);
  });
};

export default Mabashi;
