import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const Article = (props) => {
  const { date, title, body } = props;
  dayjs.extend(utc);
  dayjs.extend(timezone);
  /*
  const [created, setCreated] = React.useState("");
  React.useEffect(() => {
    const d = date.split("T")[0].split("-");
    setCreated(d[0] + "年" + d[1] + "月" + d[2] + "日");
  }, [date]);
  */
  return (
    <article className="section___article">
      <time className="section___article___date">
        {dayjs.utc(date).tz("Asia/Tokyo").format("YYYY年MM月DD日")}
      </time>
      <h3 className="section___article___title">{title}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      ></div>
    </article>
  );
};

export default Article;
